import { useTranslation } from 'next-i18next'
import React from 'react'

import { ButtonLink } from '@/components/form'
import { CdnImage } from '@/components/media'
import { PageContainer } from '@/components/PageContainer'
import { Seo } from '@/components/Seo'
import { shared } from '@/images/cdnImages'
import { CdnFile, resizedCdnUrl } from '@/lib/cdn'
import { defaultGetStaticProps } from '@/lib/i18n'
import { discoverURL } from '@/lib/url'

type Props = {
  desc: string
  button: string
  image: CdnFile
}
export const Error = ({ desc, button, image }: Props) => (
  <div className="mx-auto w-full max-w-[1600px] px-6 py-16 text-center sm:py-20 sm:px-8 md:px-12 md:py-24 lg:px-24 xl:px-36">
    <CdnImage className="mx-auto max-w-[400px]" cdnSrc={image} />
    <p className="ts-body-2 mb-12 text-gray-500">{desc}</p>
    <ButtonLink
      className="mb-8"
      colorScheme="primary-1"
      href={discoverURL({ tab: 'items', query: {} })}
    >
      {button}
    </ButtonLink>
  </div>
)

const NotFoundError = () => {
  const { t } = useTranslation()
  const title = t('404 Not Found')
  const desc = t('The page requested can not be found.')
  const button = t('Navigate back home')

  return (
    <>
      <Seo
        title={title}
        description={desc}
        image={resizedCdnUrl(shared.og, 1440)}
      />
      <PageContainer>
        <Error desc={desc} button={button} image={shared[404]} />
      </PageContainer>
    </>
  )
}

export const getStaticProps = defaultGetStaticProps

export default NotFoundError
